/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import SiteContext from '../context/SiteContext'

import Header from "./Nav"
import Footer from "./Footer"
import PageHeader from "./PageHeader"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"
import "../styles/layout.scss"

import faviconred from "../images/favicon-red.ico";
import faviconblue from "../images/favicon-dark-blue.ico";


const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const { site } = useContext(SiteContext)

    let faviconLinks = [
        { rel: "icon", type: "image/png", sizes: "16x16", href: faviconred },
        { rel: "icon", type: "image/png", sizes: "32x32", href: faviconred },
    ];
        

    let basfColors = {
        basfOrange: '#ffc510',
        basfOrangeLightened: '#FACF8C',
        basfBlue: '#00509D',
        basfBlueLightened: '#6692c0',
        basfRed: '#C50022',
        basfRedLightened: '#DC667A',
        basfGreen: '#99C24D',
        buttonGreen: '#99C24D',
    }

    const createDynamicMuiTheme = (context) => {
        // console.log("sitecolor", context.site)
        let color = basfColors.basfRed
        let colorLightened = basfColors.basfRedLightened
        return createMuiTheme({
            // overrides: {
            //     // Name of the component ⚛️ / style sheet
            //     MuiOutlinedInput: {
            //         root: {
            //             color: '#666',
            //             fontSize: 16,
            //             '&$focused $notchedOutline': {
            //                 borderWidth: 2,
            //                 borderColor: color,
            //             },
            //             '& $notchedOutline': {
            //                 borderWidth: 2,
            //                 borderRadius: 5,
            //             },
            //             "&:hover:not($disabled):not($error) $notchedOutline": {
            //                 borderWidth: 2,
            //                 borderColor: color,
            //             },
            //             "&:focused:not($disabled):not($error) $notchedOutline": {
            //                 borderWidth: 2,
            //                 borderColor: color,
            //             },
            //             '&$error $notchedOutline': {
            //                 borderColor: color,
            //             },
            //         }, // end root
            //     }, // end MuiOutlinedInput
            //     MuiInputLabel: {
            //         root: {
            //             color: color,
            //             fontSize: 15,
            //             '&$focused': {
            //                 color: color,
            //             },
            //         },
            //     }, // end MuiInputLabel
            // },
            typography: { useNextVariants: true },
        });
    };
 
    return (
        <SiteContext.Consumer>
            {site => (
                <MuiThemeProvider theme={createDynamicMuiTheme(site)}>
                    <div className="site site--finestone">
                        <Helmet link={faviconLinks}>
                            <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                        </Helmet>
                        <Header />
                        <div className="app-wrapper">
                            <main>{children}</main>
                        </div>
                    </div>
                </MuiThemeProvider>
            )}
        </SiteContext.Consumer>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
